/**
 * Settings Module
 */

import {
  headerFilters,
  languages,
  routerAnimations,
  sidebarFilters,
  themes
} from './data';

const state = {
  darkMode: false, // dark mode
  collapseSidebar: false, // mini sidevar
  rtlLayout: false, // rtl layout
  backgroundImage: false, // enable sidebar background image
  horizontalLayoutSidebar: false, // horizontal layout sidebar
  languages, // languages
  selectedLocale: languages[0], // selected locale
  sidebarFilters, // sidebar filters
  sidebarSelectedFilter: sidebarFilters[0], // selected sidebar filter
  routerAnimations, // router animations
  selectedRouterAnimation: routerAnimations[0], // selected router animation
  themes, // themes
  selectedTheme: themes[0], // selected theme
  headerFilters, // header filters
  activeHeaderFilter: headerFilters[1], // selected header filter
  mobileSearchForm: false, // Is Mobile Search Form Open
  currencyCode: null,
  sidebarDrawer: null,
  chatMessageCount: 0,
  outerNavWidth: 70,
  innerNavWidth: 250,
  showChat: false,
  chatLoader: true,
  isFreshChatInitalized: false,
  enableUserBlock: true,
  customFontSelected: false
};

// getters
const getters = {
  chatLoader: state => {
    return state.chatLoader;
  },
  showChat: state => {
    return state.showChat;
  },
  outerNavWidth: state => {
    return state.outerNavWidth;
  },
  innerNavWidth: state => {
    return state.innerNavWidth;
  },
  chatMessageCount: state => {
    return state.chatMessageCount;
  },
  darkMode: state => {
    return state.darkMode;
  },
  collapseSidebar: state => {
    return state.collapseSidebar;
  },
  boxLayout: state => {
    return state.boxLayout;
  },
  rtlLayout: state => {
    return state.rtlLayout;
  },
  backgroundImage: state => {
    return state.backgroundImage;
  },
  selectedLocale: state => {
    return state.selectedLocale;
  },
  languages: state => {
    return state.languages;
  },
  sidebarFilters: state => {
    return state.sidebarFilters;
  },
  sidebarSelectedFilter: state => {
    return state.sidebarSelectedFilter;
  },
  selectedRouterAnimation: state => {
    return state.selectedRouterAnimation;
  },
  routerAnimations: state => {
    return state.routerAnimations;
  },
  themes: state => {
    return state.themes;
  },
  selectedTheme: state => {
    return state.selectedTheme;
  },
  headerFilters: state => {
    return state.headerFilters;
  },
  activeHeaderFilter: state => {
    return state.activeHeaderFilter;
  },
  horizontalLayoutSidebar: state => {
    return state.horizontalLayoutSidebar;
  },
  mobileSearchForm: state => {
    return state.mobileSearchForm;
  },
  isCurrencyCodeSet: state => (state.currencyCode ? true : false),
  currencyFormatSettings: state =>
    state.currencyCode
      ? {
        key: 'currency',
        currency: state.currencyCode,
        minimumFractionDigits: 2
      }
      : { minimumFractionDigits: 2 },
  sidebarDrawer: state => state.sidebarDrawer,
  isFreshChatInitalized: state => state.isFreshChatInitalized,
  enableUserBlock: state => state.enableUserBlock,
  customFontSelected: state => state.customFontSelected
};

// actions
const actions = {
  setShowChat(context, payload) {
    context.commit('setShowChat', payload);
  },
  setOuterNavWidth(context, payload) {
    context.commit('setOuterNavWidth', payload);
  },
  setInnerNavWidth(context, payload) {
    context.commit('setInnerNavWidth', payload);
  },
  darkModeHandler(context) {
    context.commit('darkModeHandler');
  },
  collapseSidebar(context) {
    context.commit('collapseSidebarHandler');
  },
  boxLayout(context) {
    context.commit('boxLayoutHandler');
  },
  rtlLayout(context) {
    context.commit('rtlLayoutHandler');
  },
  backgroundImage(context) {
    context.commit('sidebarBgImageHandler');
  },
  updateMessageCount(context, payload) {
    context.commit('updateMessageCount', payload);
  },
  setChatLoader(context, payload) {
    context.commit('setChatLoader', payload);
  },
  changeLanguage(context, payload) {
    context.commit('changeLanguageHandler', payload);
  },
  changeBackgroundImage(context, payload) {
    context.commit('changeBackgroundImageHandler', payload);
  },
  changeSidebarFilter(context, payload) {
    context.commit('changeSidebarFilterHandler', payload);
  },
  changeRouterAnimation(context, payload) {
    context.commit('changeRouterAnimationHandler', payload);
  },
  changeTheme(context, payload) {
    context.commit('changeThemeHandler', payload);
  },
  changeHeaderFilter(context, payload) {
    context.commit('changeHeaderFilterHandler', payload);
  },
  setMiniSidebarLayout(context, payload) {
    context.commit('setMiniSidebarLayoutHandler', payload);
  },
  toggleHorizontalLayoutSidebar(context, payload) {
    context.commit('toggleHorizontalLayoutSidebarHandler', payload);
  },
  toggleSearchForm(context) {
    context.commit('toggleSearchFormHandler');
  },
  setCurrencyCode(context, payload) {
    context.commit('setCurrencyCode', payload);
  },
  toggleSidebarDrawer(context) {
    context.commit('toggleSidebarDrawer');
  },
  setSidebarDrawer(context, payload) {
    context.commit('setSidebarDrawer', payload);
  },
  setIsFreshChatInitalized(context, payload) {
    context.commit('setIsFreshChatInitalized', payload);
  },
  setEnableUserBlock(context, payload) {
    context.commit('setEnableUserBlock', payload);
  },
  setCustomFontSelected(context, payload){
    context.commit('setCustomFontSelected', payload);
  }
};

// mutations
const mutations = {
  setShowChat(state, showChat) {
    state.showChat = showChat;
  },
  setOuterNavWidth(state, outerNavWidth) {
    state.outerNavWidth = outerNavWidth;
  },
  setInnerNavWidth(state, innerNavWidth) {
    state.innerNavWidth = innerNavWidth;
  },
  setChatLoader(state, chatLoader) {
    state.chatLoader = chatLoader;
  },
  updateMessageCount(state, chatMessageCount) {
    state.chatMessageCount = chatMessageCount;
  },
  darkModeHandler(state) {
    state.darkMode = !state.darkMode;
  },
  collapseSidebarHandler(state) {
    state.collapseSidebar = !state.collapseSidebar;
  },
  boxLayoutHandler(state) {
    state.boxLayout = !state.boxLayout;
  },
  rtlLayoutHandler(state) {
    state.rtlLayout = !state.rtlLayout;
  },
  sidebarBgImageHandler(state) {
    state.backgroundImage = !state.backgroundImage;
  },
  changeLanguageHandler(state, language) {
    state.selectedLocale = language;
    if (language.locale === 'he' || language.locale === 'ar') {
      state.rtlLayout = true;
    } else {
      state.rtlLayout = false;
    }
  },
  changeBackgroundImageHandler(state, image) {
    state.selectedSidebarBgImage = image;
  },
  changeSidebarFilterHandler(state, filter) {
    state.sidebarSelectedFilter = filter;
  },
  changeRouterAnimationHandler(state, animation) {
    state.selectedRouterAnimation = animation;
  },
  changeThemeHandler(state, theme) {
    state.selectedTheme = theme;
  },
  changeHeaderFilterHandler(state, filter) {
    state.activeHeaderFilter = filter;
  },
  setMiniSidebarLayoutHandler(state, isSet) {
    state.collapseSidebar = isSet;
  },
  toggleHorizontalLayoutSidebarHandler(state, value) {
    state.horizontalLayoutSidebar = value;
  },
  toggleSearchFormHandler(state) {
    state.mobileSearchForm = !state.mobileSearchForm;
  },
  setCurrencyCode(state, currencyCode) {
    state.currencyCode = currencyCode;
  },
  toggleSidebarDrawer(state) {
    state.sidebarDrawer = !state.sidebarDrawer;
  },
  setSidebarDrawer(state, value) {
    state.sidebarDrawer = value;
  },
  setIsFreshChatInitalized(state, value) {
    state.isFreshChatInitalized = value;
  },
  setEnableUserBlock(state, value){
    state.enableUserBlock = value;
  },
  setCustomFontSelected(state, value){
    state.customFontSelected = value;
  }
};

export default {
  state,
  getters,
  actions,
  mutations
};
